import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-scroll";
import Logo from "../assets/logo.svg";
import { useContext } from "react";
import LangContext from "../context/LangContext";
import SideCards from "./SideCards";
import MobileNav from "./MobileNav";

const Navbar = () => {
  const { text, handleLang } = useContext(LangContext);
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <nav className="fixed w-full h-[80px] flex justify-between items-center px-4 bg-gradient-to-b from-[#22162B] to-transparent text-gray-300 ">
      <div>
        <Link to="home" smooth={true} duration={350}>
          <img
            src={Logo}
            alt="Logo"
            style={{
              width: "70px",
              filter: "invert(100%)",
            }}
          />
        </Link>
      </div>
      {/*menu */}

      <ul className="hidden md:flex">
        <li onClick={(e) => handleLang(e)}>
          {text.navAbout === "Sobre mí" ? "ES" : "EN"}
        </li>
        <Link to="home" smooth={true} duration={500}>
          <li>Home</li>
        </Link>
        <Link to="about" smooth={true} duration={500}>
          <li>{text.navAbout}</li>
        </Link>
        <Link to="skills" smooth={true} duration={500}>
          <li>Skills</li>
        </Link>
        <Link to="work" smooth={true} duration={500}>
          <li>{text.navWork}</li>
        </Link>
        <Link to="contact" smooth={true} duration={500}>
          <li>{text.navContact}</li>
        </Link>
      </ul>

      {/*ANVORGUESA*/}
      <div onClick={handleClick} className="md:hidden z-10">
        {!nav ? <FaBars /> : <FaTimes />}
      </div>
      {/*móbile */}
      <MobileNav nav={nav} text={text} handleClick={handleClick} handleLang={handleLang}/>
      {/*SOCIAL ICONs */}
      <SideCards />
    </nav>
  );
};

export default Navbar;
