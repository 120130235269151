import React from "react";

const SkillCard = ({ skill }) => {
  const { name, icon } = skill;

  const styles = {
    class: "shadow-md rounded-md p-1 shadow-[#040c16]",
  };

  return (
    <article
      key={name + " card"}
      className={styles.class}
    >
      <img
        className={`${
          name === "Zustand" ? "w-3/4 mx-auto -mb-4" : "w-20 mx-auto"
        }`}

        src={icon}
        alt={name + " logo"}
      />
      <p className="my-2">{name}</p>
    </article>
  );
};

export default SkillCard;
