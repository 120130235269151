
import React from "react";
import { LangProvider } from "./context/LangContext";
import KonamiCodeListener from "./components/KonamiCodeListener";
import Layout from "./layout";
import Home from "./sections/Home";
import About from "./sections/About";
import Skills from "./sections/Skills/Skills";
import Work from "./sections/Work/Work";
import Contact from "./sections/Contact";
import Footer from "./sections/Footer";

function App() {
  return (
    <>
      <LangProvider>
        <Layout>
          <Home />
          <About />
          <KonamiCodeListener />
          <Skills />
          <Work />
          <Contact />
          <Footer />
        </Layout>
      </LangProvider>
    </>
  );
}

export default App;
