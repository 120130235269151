import React from 'react'
import Navbar from '../components/Navbar'

const Layout = ({children}) => {
  return (
    <main className='bg-gradient-to-b from-[#22162b] to-[#682c58]'>
        <Navbar />
        {children}</main>
  )
}

export default Layout