import NodeJsIcon from "../assets/Techs/nodejs-icon-logo-svgrepo-com.svg";
import JavaScriptIcon from "../assets/Techs/javascript-svgrepo-com.svg";
import ReactJsIcon from "../assets/Techs/react-svgrepo-com.svg";
import ReduxIcon from "../assets/Techs/redux-svgrepo-com.svg";
import NextIcon from "../assets/Techs/nextjs-icon-svgrepo-com.svg";
import ExpressJsIcon from "../assets/Techs/express-svgrepo-com.svg";
import MongoIcon from "../assets/Techs/mongo-svgrepo-com.svg";
import SequelizeIcon from "../assets/Techs/sequelize-svgrepo-com.svg";
import PostgresIcon from "../assets/Techs/postgresql-svgrepo-com.svg";
import MaterialIcon from "../assets/Techs/material-ui-svgrepo-com.svg";
import CssIcon from "../assets/Techs/css-svgrepo-com.svg";
import HtmlIcon from "../assets/Techs/html-5-svgrepo-com.svg";
import BootstrapIcon from "../assets/Techs/bootstrap-4-logo-svgrepo-com.svg";
import NestJsIcon from "../assets/Techs/nestjs-svgrepo-com.svg";
import TypeScriptIcon from "../assets/Techs/typescript-svgrepo-com.svg";
import TailwindIcon from "../assets/Techs/tailwind-svgrepo-com.svg";
import AngularIcon from "../assets/Techs/angular-svgrepo-com.svg";
import ZustandLogo from "../assets/Techs/zustand-logo.png";
import MySQLIcon from "../assets/Techs/mysql-svgrepo-com.svg";
import PythonIcon from "../assets/Techs/python-svgrepo-com.svg";
import QuackIcon from "../assets/Techs/QuackScriptLogo.png";
import FastapiIcon from "../assets/Techs/fastapi-svgrepo-com.svg";
import CoreUIcon from "../assets/Techs/coreui-c-svgrepo-com.svg";
import GoIcon from "../assets/Techs/go-gopher-svgrepo-com.svg";
import S3Icon from "../assets/Techs/aws-s3-svgrepo-com.svg";
import CWIcon from "../assets/Techs/aws-cloudwatch-svgrepo-com.svg";
import LambdaIcon from "../assets/Techs/aws-lambda-svgrepo-com.svg";
import EC2Icon from "../assets/Techs/aws-ec2-svgrepo-com.svg";

export const dataTechs = [
  { icon: NodeJsIcon, name: "NodeJs", area: "Backend", type: "Framework" },
  {
    icon: JavaScriptIcon,
    name: "JavaScript",
    area: "Fullstack",
    type: "Language",
  },
  { icon: PythonIcon, name: "Python", area: "Backend", type: "Language" },
  { icon: GoIcon, name: "Golang", area: "Backend", type: "Language" },
  { icon: QuackIcon, name: "QuackScript", area: "Fullstack", type: "Language" },
  { icon: FastapiIcon, name: "FastAPI", area: "Backend", type: "Framework" },
  { icon: CoreUIcon, name: "CoreUI", area: "Frontend", type: "Tool" },
  { icon: ReactJsIcon, name: "ReactJs", area: "Frontend", type: "Framework" },
  { icon: ExpressJsIcon, name: "Express", area: "Backend", type: "Framework" },
  { icon: HtmlIcon, name: "HTML", area: "Frontend", type: "Language" },
  { icon: CssIcon, name: "CSS", area: "Frontend", type: "Language" },
  { icon: MaterialIcon, name: "Material UI", area: "Frontend", type: "Tool" },
  { icon: PostgresIcon, name: "Postgres", area: "Backend", type: "Database" },
  { icon: ReduxIcon, name: "Redux", area: "Frontend", type: "Tool" },
  { icon: SequelizeIcon, name: "Sequelize", area: "Backend", type: "Tool" },
  { icon: MongoIcon, name: "MongoDB", area: "Backend", type: "Database" },
  { icon: MySQLIcon, name: "MySQL", area: "Backend", type: "Database" },
  {
    icon: TypeScriptIcon,
    name: "TypeScript",
    area: "Fullstack",
    type: "Language",
  },
  { icon: BootstrapIcon, name: "Bootstrap", area: "Frontend", type: "Tool" },
  { icon: NextIcon, name: "NextJs", area: "Fullstack", type: "Framework" },
  { icon: NestJsIcon, name: "NestJs", area: "Backend", type: "Framework" },
  {
    icon: ZustandLogo,
    name: "Zustand",
    area: "Frontend",
    type: "Tool",
  },
  { icon: TailwindIcon, name: "Tailwind", area: "Frontend", type: "Tool" },
  { icon: S3Icon, name: "AWS S3", area: "Cloud", type: "" },
  { icon: CWIcon, name: "AWS Cloudwatch", area: "Cloud", type: "" },
  { icon: EC2Icon, name: "AWS EC2", area: "Cloud", type: "" },
  { icon: LambdaIcon, name: "AWS Lambda", area: "Cloud", type: "" },
  { icon: AngularIcon, name: "Angular", area: "Frontend", type: "Framework" },
];
