import React from 'react'
import { Link } from 'react-scroll'

const MobileNav = ({nav, text, handleClick, handleLang}) => {
  return (
    <ul
    className={
      !nav
        ? "hidden"
        : "absolute top-0 left-0 w-full h-screen bg-[#451F55] flex flex-col justify-center items-center font-extrabold"
    }
  >
    <li className="pb-6 text-5xl" onClick={(e) => handleLang(e)}>
      {text.navAbout === "Sobre mí" ? "ES" : "EN"}
    </li>
    
    <li className="pb-6 text-5xl">
      <Link onClick={handleClick} to="home" smooth={true} duration={500}>
        Home
      </Link>
    </li>

    <li className="py-6 text-5xl">
      {" "}
      <Link onClick={handleClick} to="about" smooth={true} duration={500}>
        {text.navAbout}
      </Link>
    </li>
    <li className="py-6 text-5xl">
      <Link onClick={handleClick} to="skills" smooth={true} duration={500}>
        Skills
      </Link>
    </li>
    <li className="py-6 text-5xl">
      <Link onClick={handleClick} to="work" smooth={true} duration={500}>
        {text.navWork}
      </Link>
    </li>
    <li className="py-6 text-5xl">
      <Link onClick={handleClick} to="contact" smooth={true} duration={500}>
        {text.nav}
      </Link>
    </li>
  </ul>
  )
}

export default MobileNav