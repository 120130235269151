import React from 'react'
import { FaGithub, FaLinkedin } from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi'

const SideCards = () => {
  return (
    <div className='hidden lg:flex fixed flex-col top-[25%] left-0'>
    <ul>
      <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600'>
        <a
          className='flex justify-between items-center w-full text-gray-200'
          href='https://www.linkedin.com/in/angel-scutari-a4b058144/'
        >
          LinkedIn <FaLinkedin size={30} />{" "}
        </a>
      </li>
      <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]'>
        <a
          className='flex justify-between items-center w-full text-gray-200'
          href='https://github.com/AngelVDev'
        >
          Github <FaGithub size={30} />{" "}
        </a>
      </li>
      <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]'>
        <a
          className='flex justify-between items-center w-full text-gray-200'
          href='mailto:antonioscutari2@hotmail.com'
        >
          Email <HiOutlineMail size={30} />{" "}
        </a>
      </li>
    </ul>
  </div>
  )
}

export default SideCards